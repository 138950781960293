.desktop .content-management {
  position: relative;
  color: white;
  height: 30px; 
  width: 30px;
  margin: 15px 0;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  cursor: pointer;
}

.desktop > .content-management:hover {
  color: #0A7EC2;
}

.side-container {
  background-color: #0A7EC2;
  color: white;
  border-right: 8px solid #f1f1f1;
 
}

.side-container a {
  display: flex;
  color: white;
  font-size: 14pt;
  width: 200px;
  text-align: left;
  padding: 10px 15px;
  margin: 0 15px;
  align-items: center;
  text-decoration:none;
}



.side-container a:hover {
    text-decoration: underline;
}

.desktop-main {
  width: 100%;
  padding: 10px;
  margin: 10px;
}

.nav-item-wrapper {
  display: flex;
  align-items: center;
}
.nav-link:focus, .nav-link:hover {
    color: white;
}
.top-nav {
    height: 40px;
    width: 100%;
    display: flex;
    padding: 0 35px !important;
    justify-content: space-between;
    position: fixed !important;
    top: 0;
    align-items: center;
    background-color: #0A7EC2;
}

.top-nav button {
    background: none;
    border:none;
}

.full-width-dropdown {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    width: 100vw;
    max-height:85vh;
    overflow:auto;
    left: 0;
    right: 0;
    max-width: 100%;
    font-size:.8rem!important;
}

.full-width-dropdown-container {
    width:100%;
    text-align:center;
}
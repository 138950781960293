@font-face {
  font-family: "Poppins-Medium";
  src: url(../src/fonts/Poppins-Medium.ttf) format("truetype");
}

body {
    margin: 0;
    font-family: "Poppins-Medium", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f1f1f1 !important;
    padding-top: env(safe-area-inset-top, 20px);
    padding-bottom: env(safe-area-inset-bottom), 20px;
    user-select: none;
    cursor: default;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-primary {
    background-color: #0A7EC2!important;
}

.nav-border {
    border: 4px solid #0A7EC2;
}

.accordion-body {
  padding: 5px, impor !important;
}
.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}

main {
  background: white;
  border-radius: 10px;
}

h1 {
  text-align: center;
  font-weight: bold;
}

h4 {
  font-size: 20px !important;
}

.h6 {
  font-size: 14px !important;
}

.pointer {
    cursor:pointer;
}
$navbar-height: 60px;
$circle-radius: 65px;
$backgroundColor: #ffffff;

#root {
  height: 100%;
}

html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
}

* {
  box-sizing: border-box;
}

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $backgroundColor;
  position: fixed;
  bottom: 0;
}

.mobile .navbar {
    height: $navbar-height;
    width: 100%;
    min-width: 400px;
    display: flex;
    padding: 0 35px !important;
    justify-content: space-between;
    position: absolute !important;
    bottom: 0;
    align-items: center;
    background-color: #0A7EC2;
    box-shadow: 0 0 10px rgba(82, 74, 74, 0.8);
}

.icon-wrapper {
    position: relative;
    display: inline-block;
}

.fa-icon {
    font-size: 2em;
    color:white;
}

.icon-text {
    color:#0A7EC2;
    position: absolute;
    font-family: 'Courier New', serif;
    font-weight: bold;
    font-size: 1.5rem;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/*.navbar:after {
  content: "";
  position: absolute;
  bottom: 7px;
  height: 4px;
  width: 35%;
  left: 50%;
  transform: translateX(-50%);
  background-color: gray;
  opacity: 0.3;
}
*/
.circle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: calc(110px - #{$circle-radius / 2});
    margin-bottom: 0;
    height: $circle-radius;
    width: $circle-radius;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #0A7EC2;
    z-index: 9;
    box-shadow: 0 0 10px rgba(82, 74, 74, 0.8);
    transition: height 0.3s;
    aspect-ratio: 1; // Add this line
}

.circle .plus-icon {
  color: white;
  font-size: 27px;
  transition: opacity 0.3s, transform 0.2s;
}

.circle:hover .plus-icon {
  transform: rotate(360deg);
  opacity: 0;
}

.mobile .content-management {
  position: relative;
  color: white;
  height: 30px; // Adjust the height as needed
  width: 30px; // Adjust the width as needed
  margin: 15px 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  cursor: pointer;
}

.circleBackground {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(110px - #{$circle-radius + 10} / 2);
  margin-bottom: 0;
  height: calc(#{$circle-radius} + 10px);
  width: calc(#{$circle-radius} + 10px);
  border-radius: calc(#{$circle-radius + 20} / 2);
  background-color: white;
}

.icon-home,
.icon-settings,
.icon-user,
.account-settings,
.content-overview {
  font-size: 29px;
  color: $backgroundColor;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}

.pulse-icon {
  animation: pulse 1.5s infinite;
}

.icon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;

    &:hover {
        transform: scale(1.1);
    }
}

.icon-caption {
    margin-top: 5px;
    font-size: 0.6rem; 
    color: white; 
    text-align: center;
    font-weight: 500;
}



.mobile > .scrolling-text-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 40px;
    z-index: 10;
    background: #fff;
    padding: 10px;
    box-shadow: 0 0 10px rgba(82, 74, 74, 0.8);
}

.desktop-main > .scrolling-text-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size:2rem;
    top: 40px;
    z-index: 10;
    background: #fff;
    padding: 10px;
}

::placeholder {
  color: white;
}

.marquee {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  box-sizing: border-box;
}

.marquee span {
  display: inline-block;
  width: max-content;

  padding-left: 100%;
  /* show the marquee just outside the paragraph */
  will-change: transform;
  animation: marquee 15s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

/* Respect user preferences about animations */

@media (prefers-reduced-motion: reduce) {
  .marquee span {
    animation-iteration-count: 1;
    animation-duration: 0.01;
    /* instead of animation: none, so an animationend event is 
     * still available, if previously attached.
     */
    width: auto;
    padding-left: 0;
  }
}


.scrolling-text .now-playing-tag {
    padding: 5px 10px;
    color: #fff;
    background: #4ac089;
    font-size: 1rem;
    text-align: center;
}

.scrolling-text .scheduled-tag {
    padding: 5px 10px;
    color: white;
    background: #3DC4E2;
    font-size: 1rem;
    text-align:center;
}

.scrolling-text #coming-soon-gallery-header {
    color: #333;
    border-bottom: 2px solid #333;
    background: none;
    margin-top:20px;
}

.scrolling-text-section div {
    font-size:1rem;
}

.the-text.active {
    white-space: nowrap;
    overflow: hidden;
    animation: scrollRightToLeft 10s linear infinite;
}

@keyframes scrollRightToLeft {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

.App {
    text-align: left;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.align-bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}


.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.img-container img {
    max-height: 491px;
    max-width: 100%;
    object-fit: cover;
}

.gym-image {
    height: 491px !important;
}

.metrics-title {
    font-size: .8rem;
    color: #fefefe;
    background-color: #3c4c60;
    margin-bottom: 0;
    height:2rem;
    display:flex;
    justify-content:center;
    align-items:center;
}

.metrics-icon {
    width: 75%;
    height: auto;
    margin-bottom: 0;
}

.metrics-item {
    flex: 1 1 30%;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 0;
    border:none;
    padding:.3rem!important;
}

.metrics-header {
    width: 100%;
    height: auto;
    margin-bottom: 0;
}

.metrics-description {
    font-size: .7rem;
    color: #3c4c60;
    background-color: #edf3f5;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 1.4rem;
}

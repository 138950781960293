.selected-asset {
    position: absolute;
    top: 10px;
    right: 35px;
    color: lightgreen;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.unselected-asset {
    position: absolute;
    top: 10px;
    right: 35px;
    color: white;
    text-shadow: -1px 0 black 0, 0 1px black 0, 1px 0 black 0, 0 -1px black 0;
}

.digital-signage img {
    max-width: 100%;
    border:1px solid #333;
}

.upload img,
.details img {
    max-width: 300px;
}

.mobile .digital-signage-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 40px;
    z-index: 10;
    background: #fff;
    padding: 10px;
    filter: drop-shadow(0 5px 10px #000);
}

.desktop-main .digital-signage-header {
    display: flex;
    font-size: 2rem;
    align-items: center;
    justify-content: space-between;
    top: 40px;
    z-index: 10;
    background: #fff;
    padding: 10px;
}

/*
.digital-signage-header h5 {
    margin-right: 15px;
}
*/
.digital-signage-header div:not(#active-header):not(:first-child) {
    display: none;
}

.signage-section {
    margin-bottom: 25px;
}

#expired-section .image {
    filter: grayscale(100%);
    opacity: 0.7;
}

#scheduled-section .image {
    opacity: 0.7;
}

.digital-signage #coming-soon-gallery-header,
.digital-signage #expired-gallery-header {
    color: #333;
    border-bottom: 2px solid #333;
    background:none;
}


.digital-signage .now-playing-tag {
    padding: 5px 10px;
    color: #fff;
    background: #4ac089;
    font-size: 1rem;
    text-align: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.digital-signage .scheduled-tag {
    padding: 5px 10px;
    color: #fff;
    background: rgb(61, 196, 226);
    font-size: 1rem;
    text-align: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.expired-tag {
    padding: 5px 10px;
    color: #fff;
    background: #333;
    font-size: 1rem;
    text-align: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

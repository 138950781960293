.order-status .btn-primary {
    border: 1px solid white!important;
}

.oval-tracker {
    width: 100%;
    color:white!important;
}

.oval-tracker > .btn {
    background-color: #FFF !important;
    border:1px solid black!important;
    height: 30px;
    width: 30px;
    padding:3px;
    border-radius: 30px !important;
    margin: 10px !important;
    flex: unset !important;
    position: relative;
}

/* Style for the line */
.oval-tracker > .btn:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -20px; /* Adjusted to account for margin */
    height: 2px; /* Line thickness */
    width: 19px; /* Line width to span the gap */
    background-color: #000; /* Line color */
    z-index: 0; /* Set z-index to ensure line is under the buttons */
}

.oval-tracker > .btn.active, .oval-tracker > .btn.completed {
    z-index: 1; /* Higher z-index for active/completed buttons to be above the line */
}

.oval-tracker > .btn.active {
    background-color: #3DC4E2 !important;
    border: unset;
    padding: 0;
}
.status-bg {
    color: black !important;
    margin: 0 5px 10px 5px;
    border:1px solid #ccc;
    border-radius: 5px !important;
    padding: 10px;
    background-image: 
        linear-gradient(25deg, #e5e5e5 50%, transparent 50%), 
        linear-gradient(50deg, #f0f0f0 50%, transparent 50%);
    background-size: 200% 100%;
    background-position: right bottom, left bottom, right bottom;
}


.oval-tracker > .btn.completed {
     background-color: #4AC089 !important;
}

.loading-spinner {
    color: #3DC4E2;
}

.status-description {
    background: transparent !important;
}

.step-title {
    font-size: 2rem !important;
}

.assets-input {
    background: #eee;
    text-align:left;
}

.account-card-header {
    background: rgb(61, 196, 226)!important;
    color:white!important;
}

.progress-bar {
    background-color: #4ac089!important;
}

.submit-success-modal .modal-content {
    background-color: #4ac089 !important;
}

.order-info-modal .modal-content {
    background-color: rgb(61, 196, 226) !important;
}

.order-info,.history-info {
    color: #4ac089 !important;
}

.full-screen-ad {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000; /* Ensure it's above other content */
    background: black; /* Or another suitable background */
    display: flex;
    justify-content: center;
    align-items: center;
}

.full-screen-ad img,
.full-screen-ad video,
.full-screen-ad iframe {
    max-width: 100%;
    max-height: 100%;
}

.proof-ready-header {
    color: #333 !important;
    border-bottom: 2px solid #333 !important;
    background: none !important;
}

.Waiting-tag {
    padding: 5px 10px;
    color: #fff;
    background: rgb(61, 196, 226);
    font-size: 1rem;
    text-align: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.Approved-tag {
    padding: 5px 10px;
    color: #fff;
    background: #4ac089;
    font-size: 1rem;
    text-align: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.Rejected-tag {
    padding: 5px 10px;
    color: #fff;
    background: black;
    font-size: 1rem;
    text-align: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.history-card .list-group-item div {
    font-size:.8rem;
}


.w-md-200 {
    @media (min-width: 768px) {
        width: 200px;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
}